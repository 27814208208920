<template>
  <div>
    <el-form
      ref="nodeForm"
      :rules="rules"
      label-position="top"
      label-width="1000px"
      :model="nodeToBind"
      :hide-required-asterisk="false"
    >
      <el-row type="flex">
        <el-col :span="24">
          <el-form-item prop="node_name" :label="__('Name')">
            <el-input v-model="nodeToBind.node_name" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-tabs v-model="activeTab" class="tabs">
        <el-tab-pane :label="__('Text Detail')" name="text_detail">
          <div class="tabPane">
            <el-row type="flex">
              <el-col :span="24">
                <el-form-item
                  prop="variable_name"
                  :label="__('Select text from variable')"
                >
                  <el-row type="flex" :gutter="20">
                    <el-col>
                      <create-or-select
                        :allow-create="false"
                        :items="getVariableToRead"
                        label="variable_name"
                        value="variable_id"
                        :current_select="responseVariableSelection"
                        :placeholder="__('Select variable')"
                        :new-item-message="__('new variable')"
                        style="width: 100%;"
                        @change="handleVariableSelectionChange"
                      />
                    </el-col>
                  </el-row>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-tab-pane>
        <el-tab-pane :label="__('Results')" name="results">
          <template>
            <div class="tabPane">
              <el-form-item
                prop="keyword_finder_node.data.variable_rules.data"
                :label="__('Extract Words')"
              >
                <extract-words
                  v-model="wordRules"
                  :variables="singleValuedAndSecureVariables"
                  source="keyword_finder"
                  :active-variable="inputTextVariableId"
                  :inactive-expression-input="!hasInputTextVariableSelected"
                  :inactive-expression-reason="
                    __('please assign a variable in text detail')
                  "
                />
              </el-form-item>

              <el-form-item
                prop="keyword_finder_node.data.keyword_conditions.data"
                :label="__('Match Keywords')"
              >
                <match-keywords
                  v-model="keywordConditions"
                  :gotoOptions="generateGotoOptions"
                />
              </el-form-item>

              <el-form-item
                prop="otherwise"
                :label="__('Otherwise Goto Node')"
                class="is-required"
              >
                <other-wise
                  v-model="otherwiseCondition"
                  :goto-options="generateGotoOptions"
                />
              </el-form-item>
            </div>
          </template>
        </el-tab-pane>
      </el-tabs>
    </el-form>
  </div>
</template>

<script>
import BaseNode from "./BaseNode";
import CreateOrSelect from "./components/CreateOrSelect";
import MatchKeywords from "./components/MatchKeywords";
import ExtractWords from "./components/ExtractWords";
import OtherWise from "./components/OtherWise";
import _ from "lodash";
import { NODE_TYPES } from "@/constants/nodes";
import {
  filterRowsIfSomeKeyValueIsAbsent,
  getSubKeyObject,
  findKeywordCondition,
  filterRowsIfEveryKeyValueIsAbsent
} from "@/utils/collection";
import { mapGetters } from "vuex";
import { variableRulesValidation } from "@/utils/formValidationRules";

export default {
  mixins: [BaseNode],
  components: {
    ExtractWords,
    MatchKeywords,
    CreateOrSelect,
    OtherWise
  },
  data() {
    const validateOtherwiseNode = (rule, value, callback) => {
      const otherwiseNode = _.find(
        this.nodeToBind.keyword_finder_node.data.keyword_conditions.data,
        { keyword: "Otherwise" }
      );
      if (!otherwiseNode) {
        callback(__("Otherwise node cannot be empty"));
      } else {
        if (otherwiseNode.node_id === -1 && !otherwiseNode.node_name) {
          callback(__("Otherwise node cannot be empty"));
        } else if (otherwiseNode.node_id === -1) {
          !_.map(this.getValidNodes, "node_name").includes(
            otherwiseNode.node_name
          )
            ? callback()
            : callback(__("Node exists"));
        } else {
          callback();
        }
      }
    };

    /**
     * Input text variable validator
     * @argument rule
     * @argument value
     * @argument {function} callback Validator callback function
     */
    const validateInputTextVar = (rule, value, callback) => {
      const textDetailVariable = this.inputTextVariable;

      if (!textDetailVariable || textDetailVariable === "-1|variable") {
        callback(__("Input text variable is required"));
      } else {
        callback();
      }
    };

    /**
     * Input text variable validator(detect whether the variable_rules are all filled)
     * @argument rule
     * @argument value
     * @argument {function} callback Validator callback function
     */
    const validateVariableRules = (rule, value, callback) => {
      let isValid = variableRulesValidation(rule, value, this);
      isValid
        ? callback()
        : callback(new Error(__("word rules configuration incomplete")));
    };

    return {
      rules: {
        otherwise: {
          validator: validateOtherwiseNode,
          trigger: "change"
        },
        variable_name: {
          required: true,
          validator: validateInputTextVar,
          trigger: "change"
        },
        keyword_finder_node: {
          data: {
            variable_rules: {
              data: { validator: validateVariableRules }
            }
          }
        }
      },
      activeTab: "text_detail"
    };
  },
  computed: {
    ...mapGetters("variables", {
      systemVariables: "systemVariables"
    }),

    /**
     * Get all variable that we can use for input
     */
    getVariableToRead: function() {
      return _.concat(
        this.singleValuedAndSecureVariables,
        this.systemVariables
      );
    },

    responseVariable: {
      get: function() {
        const { data: variable_rules } = this.allVariableRules;
        return _.isEmpty(variable_rules)
          ? this.inputTextVariableId
          : this.findTextVariableRule(_.cloneDeep(variable_rules));
      },
      set: function(responseVariable) {
        if (
          // append otherwise node to keyword conditions only if otherwise node content changes
          !_.isEqual(this.responseVariable, responseVariable)
        ) {
          let { data: variable_rules } = this.allVariableRules;
          variable_rules.push(responseVariable);
        }
      }
    },

    /**
     * Get the selected response variable
     */
    responseVariableSelection() {
      return this.inputTextVariableId === -1
        ? this.inputTextVariableName
        : this.inputTextVariableId;
    },

    /**
     * Get/set collection of word rules
     * @returns {Object[]}
     */
    wordRules: {
      get: function() {
        const { data: variable_rules } = this.allVariableRules;
        return _.isEmpty(variable_rules) ? [] : variable_rules;
      },
      set: function(val) {
        this.newVariableCreated = _.some(
          val,
          variable => variable.variable_id === -1
        );
        const wordRulesExceptTextVariable = this.wordRules;
        if (
          // update only if there is a change in word rules
          !_.isEqual(wordRulesExceptTextVariable, val) ||
          _.isEmpty(wordRulesExceptTextVariable)
        ) {
          this.$set(
            this.nodeToBind.keyword_finder_node.data.variable_rules,
            "data",
            val
          );
        }
      }
    },
    allVariableRules() {
      return _.isEmpty(
        _.get(this.nodeToBind, "keyword_finder_node.data.variable_rules", [])
      )
        ? { data: [] }
        : {
            data: _.map(
              this.nodeToBind.keyword_finder_node.data.variable_rules.data,

              variableRule => {
                variableRule["msg"] = variableRule["msg"] || "";
                variableRule["default_value"] =
                  variableRule["default_value"] || "";

                return variableRule;
              }
            )
          };
    },
    allKeywordConditions() {
      return _.isEmpty(
        this.nodeToBind.keyword_finder_node.data.keyword_conditions
      )
        ? { data: [] }
        : {
            data: _.map(
              this.nodeToBind.keyword_finder_node.data.keyword_conditions.data,

              keywordCondition => {
                keywordCondition["error"] = "";
                if (
                  keywordCondition.node_id === -1 &&
                  !this.attemptedToSubmit
                ) {
                  keywordCondition["error"] = !_.map(
                    this.getValidNodes,
                    "node_name"
                  ).includes(keywordCondition.node_name)
                    ? ""
                    : __("Node ':nodeName' cannot be used here", {
                        nodeName: keywordCondition.node_name
                      });
                }
                if (
                  keywordCondition.node_id === -1 &&
                  !this.attemptedToSubmit &&
                  keywordCondition.node_name.toLowerCase() === "disconnect"
                ) {
                  keywordCondition["error"] =
                    " '" +
                    `${keywordCondition.node_name}` +
                    "' " +
                    __("cannot be used as node name");
                }

                keywordCondition["keyword_error"] = "";

                const subKeys = ["node_id", "node_name", "keyword"];

                keywordCondition["keyword_error"] = _.some(
                  this.nodeToBind.keyword_finder_node.data.keyword_conditions
                    .data,
                  condition =>
                    !_.isEqual(
                      getSubKeyObject(keywordCondition, subKeys),
                      getSubKeyObject(condition, subKeys)
                    ) &&
                    keywordCondition.keyword.toString().trim() ===
                      condition.keyword.toString().trim()
                )
                  ? "Keyword conflict"
                  : "";

                keywordCondition["msg"] = !keywordCondition.error
                  ? keywordCondition["msg"] || ""
                  : "";

                return keywordCondition;
              }
            )
          };
    },
    keywordConditions: {
      get: function() {
        const { data: keyword_conditions } = this.allKeywordConditions;
        return _.isEmpty(keyword_conditions)
          ? []
          : _.filter(
              keyword_conditions,
              condition => condition.keyword !== "Otherwise"
            );
      },
      set: function(val) {
        const keywordMatchesExceptOtherwiseCondition = this.keywordConditions;
        if (
          // update only if there is a change in keyword conditions excluding the otherwise node
          !_.isEqual(keywordMatchesExceptOtherwiseCondition, val) ||
          _.isEmpty(keywordMatchesExceptOtherwiseCondition)
        ) {
          const otherwiseCondition = this.otherwiseCondition;

          this.$set(
            this.nodeToBind.keyword_finder_node.data.keyword_conditions,
            "data",
            val
          );

          if (!_.isEmpty(otherwiseCondition)) {
            this.nodeToBind.keyword_finder_node.data.keyword_conditions.data.push(
              otherwiseCondition
            );
          }
        }
      }
    },

    /**
     * Getter & setter for the node's input text variable
     * @returns {string}
     */
    inputTextVariable: {
      get: function() {
        return _.get(
          this.nodeToBind.keyword_finder_node,
          "data.property_rules.data.input_text_variable",
          "-1|variable"
        );
      },
      set: function(val) {
        let existingData = {
          ...this.nodeToBind.keyword_finder_node.data.property_rules.data
        };
        existingData["input_text_variable"] = val;

        this.$set(
          this.nodeToBind.keyword_finder_node.data.property_rules,
          "data",
          existingData
        );
      }
    },

    /**
     * Get the selected input text variable ID
     * @returns {number}
     */
    inputTextVariableId: {
      get: function() {
        const variableId = this.inputTextVariable.split("|")[0];
        return Number.parseInt(variableId);
      }
    },

    /**
     * Get the selected input text variable name
     * @returns {string}
     */
    inputTextVariableName: {
      get: function() {
        const varDescriptor = this.getVariableToRead
          .filter(variable => variable.variable_id === this.inputTextVariableId)
          .pop();
        return _.get(varDescriptor, "variable_name", "");
      }
    },

    /**
     * Check if input text variable has been filled
     * @returns {boolean}
     */
    hasInputTextVariableSelected: function() {
      return !!this.inputTextVariableName;
    },

    /**
     * Getter and setter for the otherwise branch condition target
     * @returns {object}
     */
    otherwiseCondition: {
      get: function() {
        const { data: keyword_conditions } = this.allKeywordConditions;
        return _.isEmpty(keyword_conditions)
          ? {}
          : findKeywordCondition(_.cloneDeep(keyword_conditions), "Otherwise");
      },

      set: function(otherwiseCondition) {
        if (
          // append otherwise node to keyword conditions only if otherwise node content changes
          !_.isEqual(this.otherwiseCondition, otherwiseCondition)
        ) {
          let { data: keyword_conditions } = this.allKeywordConditions;
          if (!_.isEmpty(keyword_conditions)) {
            let index = _.findIndex(keyword_conditions, function(condition) {
              return condition.keyword === otherwiseCondition.keyword;
            });
            if (index !== -1) {
              keyword_conditions.splice(index, 1);
            }
          } else {
            keyword_conditions = [];
          }
          keyword_conditions.push(otherwiseCondition);
          this.$set(
            this.nodeToBind.keyword_finder_node.data.keyword_conditions,
            "data",
            keyword_conditions
          );
        }
      }
    },

    /**
     * method to do extra checks to validate form, it cannot be handled by the element UI form validations
     * @returns {boolean}
     */
    formHasErrors() {
      const { data: keyword_conditions } = this.allKeywordConditions;

      return (
        _.some(keyword_conditions, condition => {
          return condition.error && condition.error.length;
        }) ||
        _.some(keyword_conditions, condition => {
          return condition.keyword_error && condition.keyword_error.length;
        }) ||
        _.some(this.wordRules, rule => {
          return rule.error && rule.error.length;
        })
      );
    }
  },
  methods: {
    findTextVariableRule(rules) {
      // Check if the select text variable is present in variable rules
      const condition = _.cloneDeep(
        _.filter(rules, rule => {
          return rule.rule_value.split("|")[1] === "variable";
        })
      );
      // Pick the condition with respect to select text variable or default template for the variable
      return _.isEmpty(condition)
        ? this.inputTextVariableId
        : _.pick(condition[0], [
            "variable_id",
            "variable_name",
            "rule_column_value"
          ]);
    },
    handleVariableSelectionChange(option) {
      this.newVariableCreated = option.value === -1;
      this.inputTextVariable = `${option.value}|variable`;
    },

    /**
     * Cleanup node form for submission
     */
    cleanUpNodeToPrepareForSubmit() {
      const nodeToCleanUp = _.cloneDeep(this.nodeToBind);

      // cleanup keyword_matches
      const keywordConditions = _.map(
        filterRowsIfSomeKeyValueIsAbsent(
          _.map(this.allKeywordConditions.data, keywordCondition => ({
            ...keywordCondition,
            keyword: keywordCondition.keyword.toString().trim(),
            node_name: keywordCondition.node_name.toString().trim()
          })),
          "keyword,node_name"
        ),
        condition => {
          const { keyword, node_id, node_name, condition_option } = condition;
          return { keyword, node_id, node_name, condition_option };
        }
      );

      this.$set(
        nodeToCleanUp.keyword_finder_node.data.keyword_conditions,
        "data",
        keywordConditions
      );

      // cleanup extract_words(only clean completely empty row)
      const variableRules = filterRowsIfEveryKeyValueIsAbsent(
        _.map(this.allVariableRules.data, variableRule => {
          const {
            variable_id,
            rule_value,
            variable_name,
            default_value
          } = variableRule;
          return {
            variable_id,
            rule_value: rule_value.toString().trim(),
            variable_name: variable_name.toString().trim(),
            default_value
          };
        }),
        "rule_value,variable_name"
      );
      this.$set(
        nodeToCleanUp.keyword_finder_node.data.variable_rules,
        "data",
        variableRules
      );

      return nodeToCleanUp;
    },
    cleanUpNode() {
      if (!this.formHasErrors) {
        this.nodeToBind = this.cleanUpNodeToPrepareForSubmit();
        this.createOrEditNode();
      } else {
        this.toggleNodeSubmit(false);
      }
    },
    replaceExpr1WithNewVariableId(variableId, rule) {
      if (!rule) {
        return rule;
      }
      let newRule = JSON.parse(rule);
      let Expr1 = newRule.expressions.Expr1;
      if (!_.isEmpty(Expr1)) {
        Expr1.parameter1 = `{{${variableId}}}`;
        return JSON.stringify(newRule);
      }
      return rule;
    }
  },
  watch: {
    /**
     * Watcher for the input text variable.
     * Update references in the word rule whenever the inputTextVariable is updated.
     */
    inputTextVariable: {
      deep: true,
      handler() {
        let variableIdToUse = this.inputTextVariableId;
        if (!_.isEmpty(this.wordRules)) {
          this.wordRules = _.map(this.wordRules, wordRule => {
            return {
              ...wordRule,
              rule_value: this.replaceExpr1WithNewVariableId(
                variableIdToUse,
                wordRule.rule_value
              )
            };
          });
        }
      }
    }
  },
  created() {
    if (
      !this.nodeToBind.node_id ||
      _.isEmpty(this.nodeToBind.keyword_finder_node)
    ) {
      this.$set(this.nodeToBind, "keyword_finder_node", {});
      this.$set(this.nodeToBind.keyword_finder_node, "data", {});
      if (
        _.isEmpty(this.nodeToBind.keyword_finder_node.data.keyword_conditions)
      ) {
        this.$set(
          this.nodeToBind.keyword_finder_node.data,
          "keyword_conditions",
          {}
        );
      }

      if (_.isEmpty(this.nodeToBind.keyword_finder_node.data.variable_rules)) {
        this.$set(
          this.nodeToBind.keyword_finder_node.data,
          "variable_rules",
          {}
        );
      }

      this.$set(
        this.nodeToBind,
        "node_type",
        NODE_TYPES.KEYWORD_FINDER.NODE_TYPE
      );
    }

    // Perform compatibility check for node configurations that is created/imported from the
    // previous implementation of the keyword finder node (see STUD-2418 ticket)
    // Convert text detail variable from variable rules to property rules
    const existingTextInputVar = _.get(
      this.nodeToBind.keyword_finder_node,
      "data.property_rules.data.input_text_variable",
      null
    );

    if (existingTextInputVar === null) {
      // Copy existing text detail variable rule from variable rules to property rules
      const varRulesFiltered = _.get(
        this.nodeToBind.keyword_finder_node,
        "data.variable_rules.data",
        [
          {
            rule_value: "-1|variable"
          }
        ]
      ).reduce((prev, current) => {
        const currentRuleValue = current.rule_value.split("|");

        let groupKey = "other";
        if (currentRuleValue.length === 2) {
          groupKey = "inputTextVar";
        }

        (prev[groupKey] = prev[groupKey] || []).push(current);
        return prev;
      }, []);

      const inputTextVar = _.get(varRulesFiltered, "inputTextVar", []);
      const otherVarRules = _.get(varRulesFiltered, "other", []);
      let newInputTextVar = "-1|variable";

      if (inputTextVar.length >= 1) {
        newInputTextVar = inputTextVar[0].rule_value;
      }

      // Use updated variable rules as the new variable rules
      this.$set(
        this.nodeToBind.keyword_finder_node.data.variable_rules,
        "data",
        otherVarRules
      );

      if (
        this.nodeToBind.keyword_finder_node.data.property_rules === undefined
      ) {
        this.$set(this.nodeToBind.keyword_finder_node.data, "property_rules", {
          data: {}
        });
      }
      this.$set(
        this.nodeToBind.keyword_finder_node.data.property_rules.data,
        "input_text_variable",
        newInputTextVar
      );
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/node-palette.scss";
@import "~@/styles/node_common.scss";

.el-form-item:last-of-type {
  margin-bottom: 20px;
}

.tabs ::v-deep .el-tabs__item.is-active {
  color: $--color-messaging_conversation-node;
}

.tabs ::v-deep .el-tabs__item:hover {
  color: $--color-messaging_conversation-node;
}

.tabs ::v-deep .el-tabs__active-bar {
  background-color: $--color-messaging_conversation-node;
}

.text-input ::v-deep textarea {
  resize: none;
}

.tabPane {
  min-height: 25vh;
  padding-right: 30px;
}
</style>
